import'./Message.css';
import { useCallback, useContext } from "react";
import { ChatContext } from "../ChatContextProvider";
import { ChatWindowContext } from './ChatWindowContextProvider';
import { format } from 'date-fns';

export default function Message({ message }) {
    const  { user } = useContext(ChatContext);
    
    const { id: userId, type: userType } = user;
    const { content, createdAt } = message;
    
    const isSent = message.actorId === userId && message.actorType === userType;

    return (
        <div className={`message ${isSent ? 'sent' : 'received'}`}>
            <div className="text">{content}</div>
            <div className="metadata">
               <ReadCount message={message} />
                <span className="timestamp">{format(new Date(createdAt), 'HH:mm:ss')}</span>            
            </div>
        </div>
    );
}

function ReadCount({ message }) {
    const  { user } = useContext(ChatContext);
    const { room } = useContext(ChatWindowContext);

    const readCount = useCallback(() => {
        if (!room || !user) return '-';

        const totalSize = room.participants.length;
        const otherParticipants = room.participants.filter(p => p.actorId !== user.id || p.actorType !== user.type);
        const otherReadCount = otherParticipants.filter(p => new Date(p.lastSeenAt) >= new Date(message.createdAt)).length;
        const result = (totalSize - 1) - otherReadCount;
        return result === 0 ? '' : result;
    }, [room, user, message]);

    return (
        <span className="read-count">{readCount()}</span>
    );
}