import { Client } from "@stomp/stompjs";

const BASE_URL = 'wss://dev-api.gguge.com';

export default class StompClient {
    constructor() {
        this.client = new Client({
            brokerURL: `${BASE_URL}/ws/v1/chat`,
            debug: function (str) {
                console.log(str);
            },
            connectHeaders: {
                "Authorization": 'Bearer ' + localStorage.getItem('Authorization')
            },
            reconnectDelay: 5000,
            heartbeatIncoming: 30000,
            heartbeatOutgoing: 30000,
        });
        this.defaultHeader = {"Authorization": 'Bearer ' + localStorage.getItem('Authorization')}
    }

    onConnect(func) {
        this.client.onConnect = (frame) => {
            func(frame);
            this.subscribe("/user/queue/errors", (frame) => {
                console.log("Error: ", frame.body);
            });
        };
    }

    onDisconnect(func) {
        this.client.onDisconnect = func;
        this.client.onWebSocketClose = func;
    }

    publish({ destination, headers, body }) {
        this.client.publish({ destination, headers: {...this.defaultHeader, headers } , body });
    }
    
    activate() {
        this.client.activate();
    }

    deactivate() {
        this.client.deactivate();
    }

    subscribe(destination, func) {
        return this.client.subscribe(destination, func);
    }

    connected() {
        return this.client.connected;
    }
}