import AxiosHttpClient from "./AxiosHttpClient";

const ApiClient = {
    getChatRoomList: async () => {
        const response = await AxiosHttpClient.sendApi('get', '/v1/chat/room', null);
        return response;
    },

    getChatRoomDetail: async (roomId) => {
        const response = await AxiosHttpClient.sendApi('get', `/v1/chat/room/${roomId}`, null);
        return response;
    },

    getMessageList: async (roomId) => {
        const response = await AxiosHttpClient.sendApi('get', `/v1/chat/room/${roomId}/message`, null);
        return response;
    },

    getRoomList: async (page, size) => {
        const url = `/v1/chat/room?page=${page}&size=${size}`;
        const response = await AxiosHttpClient.sendApi('get', url, null);
        return response;
    }
}

export default ApiClient;