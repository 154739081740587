import { createContext } from 'react';

export const ChatWindowContext = createContext({
    room: null,
    readMessage: null
})

export default function ChatWindowContextProvider({ room, readMessage, children }) {
    return (
        <ChatWindowContext.Provider
            value={{
                room,
                readMessage
            }}
        >
            {children}
        </ChatWindowContext.Provider>
    );
}