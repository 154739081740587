import { useContext, useEffect, useRef, useState } from "react";
import Message from "./Message";
import { ChatContext } from "../ChatContextProvider";
import { ChatWindowContext } from "./ChatWindowContextProvider";
import ApiClient from "../../client/ApiClient";

export default function MessageList() {
    const { stompClient, isConnected } = useContext(ChatContext);
    const { room, readMessage } = useContext(ChatWindowContext);
    const [messages, setMessages] = useState([]);
    const messagesContainerRef = useRef(null);
    const receiveMessageSubscription = useRef(null);

    useEffect(() => {
        if (!isConnected || !room) return;
    
        readMessage();
        fetchMessages();
        handleReceiveMessage();
        
    }, [isConnected, room?.id]); //eslint-disable-line

    useEffect(() => {
        scrollToBottom();
    }, [messages]); //eslint-disable-line

    const scrollToBottom = () => {
        if (messagesContainerRef.current == null) return;

        messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    };

    const handleReceiveMessage = () => {
        receiveMessageSubscription.current?.unsubscribe();

        receiveMessageSubscription.current = stompClient.subscribe(`/topic/v1/chat/room/${room.id}/message/receive`, frame => {
            var { data: message } = JSON.parse(frame.body);
            setMessages(messages => [...messages, message]);
        });
    }

    const fetchMessages = async () => {
        if (!room) return;

        const response = await ApiClient.getMessageList(room.id);
        setMessages(response.data);
    }

    if (!isConnected || messages == null) {
        return <div className="chat-messages">
            <div>Connecting...</div>
        </div>
    }

    return (
        <div className="chat-messages" ref={messagesContainerRef}>
            {messages.map(message => <Message key={message.id} message={message} />)}
        </div>
    );
}