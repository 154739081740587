import { useContext, useEffect, useRef, useState } from 'react';
import './ChatRoomList.css';
import ApiClient from '../../client/ApiClient';
import { ChatContext } from '../ChatContextProvider';

export default function ChatRoomList() {
    const [rooms, setRooms] = useState([]);
    const { user, selectedRoomIdState } = useContext(ChatContext);
    const [selectedRoomId] = selectedRoomIdState;
    const intervalRef = useRef(null);

    useEffect(() => {
        fetchRooms()
    }, [selectedRoomId, user]);

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            fetchRooms();
        }, 3000);

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []); //eslint-disable-line

    const fetchRooms = async () => {
        const response = await ApiClient.getRoomList(1, 20);
        const rooms = response.data?.items ?? [];
        setRooms(rooms);
    }

    const renderChatRooms = () => {
        if (!rooms) return (
            <div className="chat-room">
                <div className="room-name">Loading...</div>
            </div>
        );

        return rooms.map((room, index) => {
            return <ChatRoom key={index} room={room} fetchRooms={fetchRooms} />
        });
    }

    return (
        <div className="chat-room-list-container">
            <div className="search-bar">
                <input type="text" placeholder="Search by name or room" />
            </div>
            <div className="chat-room-list">
                {renderChatRooms()}
            </div>
        </div>
    );
}

function ChatRoom({ room, fetchRooms }) {
    const { selectedRoomIdState } = useContext(ChatContext);
    const setSelectedRoomId = selectedRoomIdState[1];
    const hasUnreadCount = room?.unreadCount !== 0;
    const roomName = () => {
        return room.participants.reduce((acc, participant, index) => {
            if (index === 0) {
                return participant.name;
            }

            return `${acc}, ${participant.name}`;
        }, "");
    };
    const lastMessagedAt = () => {
        const duration = new Date() - new Date(room.lastMessageAt);
        
        // 24시간 이내이면 "몇시간 전" 형태로 표시
        if (duration < 24 * 60 * 60 * 1000) {
            // 1분 이내이면 "몇초 전" 형태로 표시
            if (duration < 60 * 1000) {
                return `${Math.floor(duration / 1000)}초 전`;
            }

            // 1시간 이내이면 "몇분 전" 형태로 표시
            if (duration < 60 * 60 * 1000) {
                return `${Math.floor(duration / (60 * 1000))}분 전`;
            }

            return `${Math.floor(duration / (60 * 60 * 1000))}시간 전`;
        }

        // 24시간 이후이면 "YYYY-MM-DD" 형태로 표시
        return `${new Date(room.lastMessageAt).toISOString().slice(0, 10)}`;
    }

    const handleClick = async (event) => {
        event.stopPropagation();
        setSelectedRoomId(room.id);
        fetchRooms();
    }

    return (
        <div className="chat-room" onClick={handleClick}>
            <div className="room-name">
                <div className="name">{roomName()}</div>
                <div className="last-read-time">{lastMessagedAt()}</div>
            </div>
            <div className="room-message">
            <div className="last-message">{room?.lastMessage}</div>
                {hasUnreadCount && <div className="not-read-count">{room?.unreadCount}</div>}
            </div>
        </div>
    );
}