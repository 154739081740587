import { useContext, useEffect, useRef, useState } from 'react';
import { ChatContext } from '../ChatContextProvider';
import './ChatWindow.css';
import MessageList from './MessageList';
import ApiClient from '../../client/ApiClient';
import ChatWindowContextProvider, { ChatWindowContext } from './ChatWindowContextProvider';

export default function ChatWindow({ roomId }) {
    const { stompClient, user, isConnected } = useContext(ChatContext);
    const [room, setRoom] = useState(null);
    const readMessageSubscription = useRef(null);
    
    useEffect(() => {
        if (!isConnected || !room) return;
        readMessageSubscription.current?.unsubscribe();
        readMessageSubscription.current = stompClient.subscribe(`/topic/v1/chat/room/${roomId}/read`, frame => {
            const { data: changedRoom } = JSON.parse(frame.body);
            setRoom(changedRoom);
        });
    }, [isConnected, roomId]); //eslint-disable-line

    useEffect(() => {
        if (!roomId) return;
        fetchRoom(roomId);
    }, [roomId]); //eslint-disable-line

    const fetchRoom = async (roomId) => {
        const result = await ApiClient.getChatRoomDetail(roomId);
        setRoom(result.data);
    }

    const readMessage = () => {
        if (!room || !user) return;

        stompClient.publish({ destination: `/ws/v1/chat/room/${room.id}/read` });
    }

    const handleFocus = () => {
        readMessage();
    }

    return (
        <ChatWindowContextProvider
            room={room}
            readMessage={readMessage}
        >
            <div className="chat-window-container" tabIndex="0" onFocus={handleFocus}>
                <ChatHeader />
                <MessageList room={room}/>
                <ChatForm />
            </div>
        </ChatWindowContextProvider>
        
    );
}

function ChatHeader() {
    return (
        <div className="chat-window-header">
            <div>Room Name</div>
            <div>Settings</div>
        </div>
    );
}

function ChatForm() {
    const { user, stompClient } = useContext(ChatContext);
    const { room } = useContext(ChatWindowContext);
    const inputRef = useRef(null);

    
    const sendMessage = () => {
        if (!room || !user) return;
        
        const payload =  {
            roomId: room.id,
            actorId: user.id,
            actorType: user.type,
            content: inputRef?.current?.value,
            contentType: 'TEXT'
        };

        stompClient.publish({
            destination: `/ws/v1/chat/room/${room.id}/message/send`,
            body: JSON.stringify(payload)
        });
        
        inputRef.current.value = '';
    }

    const handleKeyDown = debounce((e) => {
        if (e.key === 'Enter') {
            sendMessage();
        }
    }, 300);
    
    return (
        <div className="chat-form">
            <input type="text" placeholder="Type a message..." ref={inputRef} onKeyDown={handleKeyDown}/>
            <button onClick={sendMessage}>Send</button>
        </div>
    );
}

function debounce(func, wait) {
    let timeout;
    return function(...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }