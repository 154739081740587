import { useEffect, useState } from "react";
import ChatContextProvider from "./ChatContextProvider";
import ChatWindow from "./window/ChatWindow";
import StompClient from "../client/StompClient";
import './ChatPage.css';
import ChatRoomList from "./room/ChatRoomList";

localStorage.setItem('Authorization', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ODU1MzMsImtleSI6IjI0MTc1Nzk2MDEiLCJuYW1lIjoi642w7J2067iMIiwicGhvbmUiOiIrODIgMTAtMjQwNi00MzUxIiwiZW1haWwiOiJyb2J5ODUwMkBuYXZlci5jb20iLCJpYXQiOjE3MTM4NjIwMzJ9.yLZeYiBFow1q_6WanmQh1mm71j6HKPqHmUWJgtPIcJA');
localStorage.setItem('AuthUser', JSON.stringify({ id: 85533, type: 'USER' }));

export default function ChatPage() {
    const [user, setUser] = useState({ id: 85533, type: 'USER' });
    const [stompClient, setStompClient] = useState(new StompClient());
    const [isConnected, setIsConnected] = useState(false);
    const selectedRoomIdState = useState(null);

    useEffect(() => {
        stompClient.activate();

        stompClient.onConnect(() => {
            setIsConnected(true);
        });
        
        stompClient.onDisconnect(() => {
            setIsConnected(false);
        });
    }, [stompClient]); //eslint-disable-line
    
    const handleChange = async (e) => {
        let selectedUser;
        
        switch(e.target.value) {
            case 'Dave':
                selectedUser = { id: 85533, type: 'USER' };
                localStorage.setItem('Authorization', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ODU1MzMsImtleSI6IjI0MTc1Nzk2MDEiLCJuYW1lIjoi642w7J2067iMIiwicGhvbmUiOiIrODIgMTAtMjQwNi00MzUxIiwiZW1haWwiOiJyb2J5ODUwMkBuYXZlci5jb20iLCJpYXQiOjE3MTM4NjIwMzJ9.yLZeYiBFow1q_6WanmQh1mm71j6HKPqHmUWJgtPIcJA');
                localStorage.setItem('AuthUser', JSON.stringify({ id: 85533, type: 'USER' }));
                break;
            case 'Max':
                selectedUser = { id: 85432, type: 'USER' };
                localStorage.setItem('Authorization', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ODU0MzIsImtleSI6IjE3OTcyMDIzMDMiLCJuYW1lIjoi7KO87ZiBIiwicGhvbmUiOiIrODIgMTAtNjI2MS02MjQzIiwiZW1haWwiOiJha2FqaHl1a0BnbWFpbC5jb20iLCJpYXQiOjE3MDcxMTg3NDl9.uQoFTtqdJbdxVO6ASQuho_k6aG-P1KtrZfW-XVY0Fl4');
                localStorage.setItem('AuthUser', JSON.stringify({ id: 85432, type: 'USER' }));
                break;
            case 'Herb':
                selectedUser = { id: 85363, type: 'USER' };
                localStorage.setItem('Authorization', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ODUzNjMsImtleSI6IjIzODYxMzM5ODkiLCJuYW1lIjoi64uk7JiB7YyM7YyMIiwicGhvbmUiOiIrODIgMTAtMzUyNi02MTkwIiwiZW1haWwiOiJtb29uNjE5MEBkYXVtLm5ldCIsImlhdCI6MTcxMjgxOTA4Nn0.i_1ciKpLcHn2Ao1HnyQP5SrNug0voOJrWlcL7eZfOKU');
                localStorage.setItem('AuthUser', JSON.stringify({ id: 85363, type: 'USER' }));
                break;
            case 'Teacher-Dave':
                selectedUser = { id: 6360, type: 'TEACHER' };
                localStorage.setItem('Authorization', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NjM2MCwiZW1haWwiOiJyb2J5ODUwMkBuYXZlci5jb20iLCJuYW1lIjoi642w7J2067iM7IykIiwicGhvbmUiOiIrODIgMTAtMjQwNi00MzUxIiwiZ21haWwiOiJyb2J5ODUwMkBuYXZlci5jb20iLCJjb250cmFjdCI6IkVOQUJMRSIsImlhdCI6MTcxMzkyOTIwOH0.JOSBK1Xs9OlGOWn_Cb_HEjrbqIZMGronFXiLpzWuNwo');
                localStorage.setItem('AuthUser', JSON.stringify({ id: 6360, type: 'TEACHER' }));
                break;
            default:
                throw Error('Invalid user');
        }

        await stompClient.deactivate();

        setStompClient(new StompClient());
        setUser(selectedUser);
    }

    return (
        <ChatContextProvider
            user={user}
            setUser={setUser}
            stompClient={stompClient}
            setStompClient={setStompClient}
            isConnected={isConnected}
            selectedRoomIdState={selectedRoomIdState}
        >
            <div className="chat-container">
                <select className='user-selector' onChange={handleChange}>
                    <option value="Dave" defaultChecked>Dave</option>
                    <option value="Max">Max</option>
                    <option value="Herb">Herb</option>
                    <option value="Teacher-Dave" defaultChecked>Teacher-Dave</option>
                </select>
                <ChatRoomList/>
                <ChatWindow roomId={selectedRoomIdState[0]}/>
            </div>
            
        </ChatContextProvider>
    );
}