import axios from "axios"

const instance = axios.create({
    baseURL: "https://dev-api.gguge.com",
    timeout: 5000,
})

const EXPIRED_ACCESS_TOKEN = "액세스 토큰이 만료되었습니다."

const AxiosHttpClient = {
    sendApi: async (method, url, body) => {
        let result = null
        const authorizationHeader = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
            },
        }

        // const authUser = JSON.parse(localStorage.getItem("AuthUser"));
        // console.log("authUser", authUser);
        
        // switch (authUser.type) {
        //     case "USER":
        //         authorizationHeader.headers["X-Auth-User"] = JSON.stringify({ id: authUser.id });
        //         break
        //     case "TEACHER":
        //         authorizationHeader.headers["X-Auth-Teacher"] = JSON.stringify({ id: authUser.id });
        //         break
        //     default:
        //         break
        // }


        if (body) {
            try {
                result = await instance[method](url, body, authorizationHeader)
            } catch (e) {
                console.log(e);
                if (e?.response?.status === 401 && e.message === EXPIRED_ACCESS_TOKEN) {
                    console.error("인증 에러")
                } else {
                    console.log("Error: ", e)
                }
                
                return e.response.data
            }

            return result.datas
        }

        try {
            result = await instance[method](url, authorizationHeader)
        } catch (e) {
            console.log(e);
            if (e?.response?.status === 401 && e.message === EXPIRED_ACCESS_TOKEN) {
                console.error("인증 에러")
            } else {
                console.log("Error: ", e)
            }

            return e
        }

        return result.data
    },
    sendApiMultiPart: async (method, url, formData, token) => {
        let result = null

        const authorizationHeader = {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
            },
        }

        try {
            result = await instance[method](url, formData, authorizationHeader)
        } catch (e) {
            if (e?.response?.status === 401 && e.message === EXPIRED_ACCESS_TOKEN) {
                console.error("인증 에러")
            } else {
                console.log("Error: ", e)
            }

            return e
        }

        return result.data
    },
}

export default AxiosHttpClient