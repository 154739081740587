import { createContext } from 'react';

export const ChatContext = createContext({
    user: {id: 1, type: 'UNKOWN'},
    setUser: () => {},
    stompClient: null,
    setStompClient: null,
    selectedRoomIdState: null,
    isConnected: false
})

export default function ChatContextProvider({ user,
                                              setUser,
                                              stompClient,
                                              setStompClient,
                                              selectedRoomIdState,
                                              isConnected,
                                              children }) {
    return (
        <ChatContext.Provider
            value={{
                user,
                setUser,
                stompClient,
                setStompClient,
                selectedRoomIdState,
                isConnected
            }}
        >
            {children}
        </ChatContext.Provider>
    );
}